<template>
  <div class="sales-discount-setting">
    <PageTitle title="銷售優惠設定" btn="新增" @btnClick="openDialog('create')" />

    <FiltersContainer>
      <BaseElInput v-model="nameSearch" clearable placeholder="輸入優惠名稱" @keypress.enter.native="refresh(); tableOptions.page = 1;" @clear="refresh(); tableOptions.page = 1;">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh(); tableOptions.page = 1;" />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable v-loading="loading" :data="salesDiscountList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" label="名稱" width="150" align="center" />
        <BaseElTableColumn prop="type" label="打折形式" align="center">
          <template slot-scope="scope">
            {{
              (scope.row.type === 'free')
                ? '自定義優惠'
                : (scope.row.type === 'cash')
                  ? '現金'
                  : '比例'
            }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="type" label="金額/比例" align="center">
          <template slot-scope="scope">
            {{
              (scope.row.type === 'free')
                ? '-'
                : (scope.row.percentAmount)
                  ? `${discountFormat(scope.row.percentAmount)}折`
                  : `${scope.row.cashAmount}元`
            }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="order" label="排序" align="center" />
        <BaseElTableColumn label="操作" fixed="right" width="100" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="openDialog('update'), selectRow=scope.row, findSalesDiscount()"
              @delete="deleteDialog=true, selectRow=scope.row"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="salesDiscountCount"
        @pageChange="refresh"
      />
    </section>

    <!-- Dialog -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="resetForm()">
      <section>
        <BaseElForm ref="form" :model="formData" label-position="top" :rules="formRules">
          <BaseElFormItem label="名稱" prop="name">
            <BaseElInput v-model="formData.name" placeholder="請輸入名稱" />
          </BaseElFormItem>

          <BaseElFormItem label="打折形式" prop="needUnit">
            <BaseElSelect
              v-model="formData.type"
              placeholder="請選擇人員"
              @change="$emit('change')"
            >
              <BaseElSelectOption
                v-for="item in [{id: 'cash', name: '金額'}, {id: 'percent', name: '百分比'}, {id: 'free', name: '自定義金額'}]"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </BaseElSelect>
          </BaseElFormItem>

          <BaseElFormItem v-if="formData.type === 'cash'" label="金額" prop="cashAmount">
            <BaseElInput v-model="formData.cashAmount" placeholder="請輸入金額" />
          </BaseElFormItem>
          <BaseElFormItem v-if="formData.type === 'percent'" label="百分比" prop="percentAmount">
            <!-- <BaseElInput  placeholder="請輸入打折百分比" v-model="formData.percentAmount"/> -->
            <BaseElInputNumber v-model="formData.percentAmount" :precision="2" :step="0.01" :min="0.01" :max="1" />
            <div style="color: gray; font-size: 14px;">範例：打9折請填寫0.90，65折請填寫0.65 (最小為0.01)</div>
          </BaseElFormItem>

          <BaseElFormItem label="排序" prop="order">
            <BaseElInput v-model="formData.order" placeholder="請輸入順序" />
          </BaseElFormItem>
        </BaseElForm>
      </section>

      <div slot="footer">
        <BaseElButton plain @click="showDialog = false, resetForm()">
          取消
        </BaseElButton>
        <BaseElButton type="primary" @click="dialogConfirm">{{ (dialogType === 'create')? '新增' : '儲存' }}</BaseElButton>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteSalesDiscount(), deleteDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { noEmptyRules, isDigitRules, lengthRules, rangeRules } from '@/validation'
import EmptyBlock from '@/components/EmptyBlock.vue'
import {
  CreateSalesDiscount,
  GetSalesDiscount,
  GetSalesDiscountCount,
  FindSalesDiscount,
  UpdateSalesDiscount,
  DeleteSalesDiscount,
} from '@/api/sales'
// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import notifyMessage from '@/config/notifyMessage'

export default {
  name: 'SalesDiscountSetting',
  components: { DeleteDialog, EmptyBlock },
  computed: {
    ...mapGetters([
      'shop',
    ]),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    dialogTitle () {
      return dialogTitle(
        this.dialogType,
        {
          create: '新增銷售優惠',
          update: '編輯銷售優惠',
        },
      )
    },
  },
  data: () => ({
    loading: false,
    nameSearch: '',
    // Dialog
    showDialog: false,
    dialogType: 'create',
    deleteDialog: false,
    selectRow: null,
    selectSalesDiscount: null,

    salesDiscountCount: 0,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    formData: {
      name: '',
      type: 'cash',
      cashAmount: '',
      percentAmount: '',
      order: 100,
    },

    formRules: {
      name: noEmptyRules('請輸入類別名稱'),
      order: [isDigitRules(), noEmptyRules('請輸入排序'), rangeRules()],
      cashAmount: [isDigitRules(), noEmptyRules('請輸入金額'), rangeRules()],
      percentAmount: [noEmptyRules('請輸入百分比'), isDigitRules(), lengthRules(1, 2)],
    },

    salesDiscountList: [],
  }),

  async mounted () {
    await this.refresh()
  },
  methods: {
    async refresh () {
      this.loading = true
      await this.getSalseDiscount()
      await this.getSalesDiscountCount()
      this.loading = false
    },

    discountFormat (amount) {
      let word = (amount * 100).toFixed()
      if (word) { if (word.length === 1) word = `0.${word}` }
      if (word % 10 === 0) word = word.replace('0', '')
      return word
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    async dialogConfirm () {
      if (!await this.checkForm()) return
      const type = this.dialogType
      if (type === 'create') this.createSalseDiscount()
      if (type === 'update') this.updateSalesDiscount()
      this.showDialog = false
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        name: '',
        type: 'cash',
        cashAmount: '',
        percentAmount: '',
        order: 100,
      }
    },

    //= > 創建銷售優惠
    async createSalseDiscount () {
      const form = this.formData
      try {
        await CreateSalesDiscount({
          shopId: this.shop,
          name: form.name,
          type: form.type,
          cashAmount: (form.type === 'cash') ? +form.cashAmount : undefined,
          percentAmount: (form.type === 'percent') ? +form.percentAmount : undefined,
          order: form.order,
        })
        await this.refresh()
        this.$message.success(notifyMessage.createSuccess)
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 取得銷售優惠
    async getSalseDiscount () {
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      try {
        const res = await GetSalesDiscount({
          shopId: this.shop,
          start: startIndex,
          limit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.salesDiscountList = res
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 取得銷售優惠總數
    async getSalesDiscountCount () {
      try {
        const res = await GetSalesDiscountCount({
          shopId: this.shop,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })

        this.salesDiscountCount = res
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 取得指定銷售優惠
    async findSalesDiscount () {
      try {
        const res = await FindSalesDiscount({
          shopId: this.shop,
          id: this.selectRow.id,
        })

        this.formData = JSON.parse(JSON.stringify(res))
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 更新銷售優惠
    async updateSalesDiscount () {
      try {
        const form = this.formData
        await UpdateSalesDiscount({
          shopId: this.shop,
          id: this.selectRow.id,
          name: form.name,
          type: form.type,
          cashAmount: (form.type === 'cash') ? +form.cashAmount : 0,
          percentAmount: (form.type === 'percent') ? +form.percentAmount : 0,
          order: form.order,
        })

        await this.refresh()
        this.$message.success(notifyMessage.updateSuccess)
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 刪除銷售優惠
    async deleteSalesDiscount () {
      try {
        await DeleteSalesDiscount({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.$message.success(notifyMessage.deleteSuccess)
        await this.refresh()
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

  },
}
</script>

<style scoped lang="scss"></style>
